import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

export default class Post extends Component {
  render() {
    const post = this.props.post
    const hasImage = post.frontmatter.image ? true : false

    return (
      <article className={`${hasImage ? 'post post--image' : 'post'}`}>
        <Link className='post__permalink' to={post.fields.slug}>
          {hasImage && (
            <div
              className='post__thumbnail'
              data-aos='fade-up'
              data-aos-delay='40'>
              <GatsbyImage image={getImage(post.frontmatter.image)} />
            </div>
          )}
          <div className='post__details mt-24'>
            <p
              className='text-md text-accent mb-0'
              data-aos='fade-up'
              data-aos-delay='50'>
              {post.frontmatter.date}
            </p>
            <h2
              className='post__name title-3'
              data-aos='fade-up'
              data-aos-delay='60'>
              {post.frontmatter.title}
            </h2>
            <div
              className='post__excerpt'
              data-aos='fade-up'
              data-aos-delay='70'>
              <ReactMarkdown children={post.frontmatter.excerpt} />
            </div>
            <div data-aos='fade-up' data-aos-delay='80'>
              <button className='btn btn-red mt-8'>Continue reading</button>
            </div>
          </div>
        </Link>
      </article>
    )
  }
}
