import React from 'react'

import Post from '@/components/News/PostItem'
import { useAllNewsFeed } from '@/hooks/use-news-feed'

export default function NewsFeed() {
  const posts = useAllNewsFeed()
  return (
    <div className='feed feed--15 feed--rel'>
        {posts.length > 0 &&
          posts.map(({ node: post }) => <Post post={post} key={post.id} />)}
      </div>
  )
}
