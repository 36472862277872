import { useStaticQuery, graphql } from 'gatsby'
export const useAllNewsFeed = () => {
  const {
    allMarkdownRemark: { edges: data },
  } = useStaticQuery(
    graphql`
      query NewsFeedQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: { type: { eq: "news" }, layout: { eq: "Post" } }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "MMMM Do, YYYY")
                excerpt
                image {
                  childImageSharp {
                    gatsbyImageData(
                        width: 800,
                        quality: 45
                        formats: [AUTO, WEBP, AVIF]
                      )
                  }
                }
              }
            }
          }
        }
      }
    `,
  )
  return data
}
